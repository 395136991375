export const API_URL = 'https://test.qlitex.com:3001/api/';
export const pageSizes = [20, 50, 75, 100, 200];
export const awsCdnPrefix = "https://d2hskb47g5lnpk.cloudfront.net/";
export const userPicturePrefix = "user_profile_images/";
export const customerPicturePrefix = "customer_images/";
export const userDefaultPicture = "https://d2hskb47g5lnpk.cloudfront.net/user_profile_images/profile_image_default.avif";
export const customerDefaultPicture = "https://d2hskb47g5lnpk.cloudfront.net/customer_images/customer_default_image.png";
export const MAPS_API_KEY = 'AIzaSyAklaBs8byT3HG5Rp7fLCj32CL7uxeP6UY';
export const GEOCODING_API_KEY = 'AIzaSyAklaBs8byT3HG5Rp7fLCj32CL7uxeP6UY';
export const learnerTeacherIntroVideo = "https://d2rig861j9jdm5.cloudfront.net/other_videos/intro-video_2.mp4";
export const userManualUrl = "https://test.qlitex.com/manual/index.html";
export const learnerManualUrl = "https://test.qlitex.com/learnermanual/index.html";
export const frontendKey = "08d49c715a83f8e53c129a95d6c3e3bb4e7da3a395262a4ff3c710eab40c3a52";
export const contactUsPageVideo = "https://d2rig861j9jdm5.cloudfront.net/production/marketing/QLiteMarketing_02-V7.mp4";
